import React from 'react';
import primaIcon from './prima_monogram.svg';
import { useTheme } from '@mui/material/styles';

const useStyles = () => {
  const theme = useTheme();
  return {
    logo: {
      width: 'auto',
      height: 30,
      filter: theme.palette?.mode === 'dark' ? 'brightness(99)' : 'none',
    },
  };
};

const LogoIcon = () => {
  const styles = useStyles();

  return <img style={styles.logo} src={primaIcon} alt="Prima logo" />;
};

export default LogoIcon;
