import React from 'react';
import primaLogo from './prima_logotype.svg';
import { useTheme } from '@mui/material/styles';

const useStyles = () => {
  const theme = useTheme();

  return {
    logo: {
      width: 'auto',
      height: 40,
      filter: theme.palette?.mode === 'dark' ? 'brightness(99)' : 'none',
    },
  };
};
const LogoFull = () => {
  const classes = useStyles();

  return <img style={classes.logo} src={primaLogo} alt="Prima logo" />;
};

export default LogoFull;
