import {
  palettes,
  genPageTheme,
  shapes,
  UnifiedThemeProvider,
  createUnifiedTheme,
  createBaseThemeOptions,
} from '@backstage/theme';
import LightIcon from '@mui/icons-material/WbSunny';
import DarkIcon from '@mui/icons-material/Brightness2';
import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';

const primaLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      background: {
        default: '#f8f8f8',
        paper: '#fff',
      },
      primary: {
        main: '#067f96',
        dark: '#045767',
      },
      secondary: {
        main: '#8334c2',
        dark: '#5b2488',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#8334c2'], shape: shapes.wave2 }),
  },
});

const primaDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#85becc',
        dark: '#067f96',
      },
      secondary: {
        main: '#8334c2',
        dark: '#5b2488',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#8334c2'], shape: shapes.wave2 }),
  },
});

const themes: AppTheme[] = [
  {
    id: 'light-theme',
    title: 'Prima Light',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={primaLightTheme} children={children} />
    ),
  },
  {
    id: 'dark-theme',
    title: 'Prima Dark',
    variant: 'dark',
    icon: <DarkIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={primaDarkTheme} children={children} />
    ),
  },
];

export default themes;
