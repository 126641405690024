import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

export const backstageUserGithubIdFrontendPlugin = createPlugin({
  id: 'backstage-user-github-id-frontend',
});

export const BackstageUserGithubIdFrontendComponent =
  backstageUserGithubIdFrontendPlugin.provide(
    createComponentExtension({
      name: 'BackstageUserGithubId',
      component: {
        lazy: () =>
          import('./GithubIdBackendSync').then(m => m.GithubIdBackendSync),
      },
    }),
  );
